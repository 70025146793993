import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/app/page.css");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/component/Blog/RecentBlog.js");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/component/Demo/DemoButton.js");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/component/FAQ/FAQ.js");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/component/HomePage/Banner.js");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/component/HomePage/Feature.js");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/component/IndustrySection/IndustrySection.js");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/androidDigital/android_tv_report_features.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/androidDigital/content_playlist_scheduling.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/androidDigital/Easy_accessibility.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/androidDigital/easy_device_pairing.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/androidDigital/Functions_in_offline_&_online.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/androidDigital/Real_time_content_syncing.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/Create intuitive playlist.png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/Display media on-screen.png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/Instinctive scheduling.png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/on-premise 1 (3).png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/on-premise 1 (4).png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/on-premise 1 (5).png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/On-premise deployment.png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/Real-time display.png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/featurelist/Remote screen management.png");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/Ads_report.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/Andrioid_tv_report.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/campaign_management.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/Content_management_system.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/Media.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/on_premise_deployment.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/Playlist.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/screen_uptime_report.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/ourFeatureSection/Unlinted_screens.svg");
import(/* webpackMode: "eager" */ "/var/www/html/BB_Marketing_Website/nextjs/src/Helper/images/self_hosted.png")